import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { CssBaseline, Link, Skeleton } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { initAuth } from '@newedge/auth';
import { createRelayEnvironment, RelayRouteWithQuery } from '@newedge/relay';
import { wrapAuthRoutes } from '@newedge/routing';
import { useEffect } from 'react';
import { RouteObject, useRoutes } from 'react-router';
import { appConfig, allowedClaims } from './config/appConfig';
import { RelayEnvironmentProvider } from 'react-relay';
import { Link as RouterLink } from 'react-router-dom';
import {
  InternalAccessPageWrapper,
  InternalAccessPageWrapperQueryNode,
} from './views/InternalAccessPageWrapper';
import { NewEdgeThemeProvider } from '@newedge/theme';
import { GlobalFilterProvider } from './components/context/GlobalFilterContext';

const { msalClient, accessScopes, getAccessToken } = initAuth(appConfig);

const gatewayRelayEnv = createRelayEnvironment({
  getAccessToken,
  endpoint: appConfig.apiDomain,
  scopes: [accessScopes.gatewayApi],
});
if (!gatewayRelayEnv) throw new Error('Could not initialize GraphQL client!');

const authRoutes: RouteObject[] = [
  {
    path: '*',
    element: (
      <RelayRouteWithQuery
        innerComponent={InternalAccessPageWrapper}
        query={InternalAccessPageWrapperQueryNode}
        initialVariables={{}}
        fallback={<Skeleton variant='rectangular' width='100%' height={200} />}
      />
    ),
  },
];

const errorLinkRenderer = () => {
  return (
    <div>
      <Link component={RouterLink} to='/my/clients'>
        Go back to clients page
      </Link>
    </div>
  );
};

const wrappedRoutes = wrapAuthRoutes(
  authRoutes,
  allowedClaims,
  '/my/clients',
  errorLinkRenderer
);

export function App() {
  const cancelPasswordResetErrorCode = 'AADB2C90091';
  const prefersDarkMode = true; // useMediaQuery('(prefers-color-scheme: dark)');

  const isCancelPasswordResetEvent = (
    event: EventMessage
  ): boolean | undefined => {
    return (
      event.eventType === EventType.LOGIN_FAILURE &&
      event.error?.message?.includes(cancelPasswordResetErrorCode)
    );
  };

  useEffect(() => {
    msalClient.enableAccountStorageEvents();
    // Handles setting the primary account during a login and logging out across tabs
    const callbackId = msalClient.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const { account } = payload;
        msalClient.setActiveAccount(account);
      } else if (event.eventType === EventType.ACCOUNT_ADDED) {
        const newAccount = event.payload as AccountInfo;
        msalClient.setActiveAccount(newAccount);
      } else if (event.eventType === EventType.ACCOUNT_REMOVED) {
        const removedAccount = event.payload as AccountInfo;
        msalClient.logoutRedirect({
          account: removedAccount,
        });
      } else if (isCancelPasswordResetEvent(event)) {
        msalClient.loginRedirect();
      }
    });

    return () => {
      msalClient.disableAccountStorageEvents();
      if (!callbackId) return;
      msalClient.removeEventCallback(callbackId);
    };
  }, []);

  const routes = useRoutes(wrappedRoutes);

  return (
    <MsalProvider instance={msalClient}>
      <RelayEnvironmentProvider environment={gatewayRelayEnv}>
        <StyledEngineProvider injectFirst>
          <NewEdgeThemeProvider prefersDarkMode={prefersDarkMode}>
            <CssBaseline />
            <GlobalFilterProvider>{routes}</GlobalFilterProvider>
          </NewEdgeThemeProvider>
        </StyledEngineProvider>
      </RelayEnvironmentProvider>
    </MsalProvider>
  );
}

export default App;
