/**
 * @generated SignedSource<<e70f7031cc6840ee8354a9fe95889e40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GetClientUserInput = {
  clientUserIds: ReadonlyArray<any>;
};
export type ClientModelFilterInput = {
  accounts?: ListFilterInputTypeOfClientAccountModelFilterInput | null;
  and?: ReadonlyArray<ClientModelFilterInput> | null;
  getAccountActivity?: ClientAccountActivityModelFilterInput | null;
  getAccountBalances?: ListFilterInputTypeOfAccountBalancesModelFilterInput | null;
  getAssetAllocations?: ListFilterInputTypeOfGetAssetAllocationModelFilterInput | null;
  getCashFlowDetailProjection?: ListFilterInputTypeOfCashFlowDetailModelFilterInput | null;
  getHoldingsDetail?: ListFilterInputTypeOfHoldingsDetailModelFilterInput | null;
  getPerformanceByAccountGroup?: ListFilterInputTypeOfPerformanceByAccountGroupModelFilterInput | null;
  getPerformanceByAssetClassForAssetValue?: ListFilterInputTypeOfPerformanceByAssetClassForAssetValueModelFilterInput | null;
  getPerformanceByHoldingsForAccountValue?: ListFilterInputTypeOfPerformanceByHoldingsForAccountValueModelFilterInput | null;
  getPerformanceByHousehold?: ListFilterInputTypeOfPerformanceByHouseholdModelFilterInput | null;
  getPerformanceByManagementStyle?: ListFilterInputTypeOfPerformanceByManagementStyleModelFilterInput | null;
  getPerformanceByProduct?: ListFilterInputTypeOfPerformanceByProductModelFilterInput | null;
  getPerformanceBySubAssetClassForSubAssetValue?: ListFilterInputTypeOfPerformanceBySubAssetClassForSubAssetValueModelFilterInput | null;
  getPortfolioPerformance?: ListFilterInputTypeOfPortfolioPerformanceModelFilterInput | null;
  or?: ReadonlyArray<ClientModelFilterInput> | null;
  summary?: ListFilterInputTypeOfClientSummaryModelFilterInput | null;
  userId?: ComparableGuidOperationFilterInput | null;
};
export type ComparableGuidOperationFilterInput = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any> | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: ReadonlyArray<any> | null;
  nlt?: any | null;
  nlte?: any | null;
};
export type ListFilterInputTypeOfClientSummaryModelFilterInput = {
  all?: ClientSummaryModelFilterInput | null;
  any?: boolean | null;
  none?: ClientSummaryModelFilterInput | null;
  some?: ClientSummaryModelFilterInput | null;
};
export type ClientSummaryModelFilterInput = {
  and?: ReadonlyArray<ClientSummaryModelFilterInput> | null;
  netAssets?: ComparableDecimalOperationFilterInput | null;
  or?: ReadonlyArray<ClientSummaryModelFilterInput> | null;
};
export type ComparableDecimalOperationFilterInput = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any> | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: ReadonlyArray<any> | null;
  nlt?: any | null;
  nlte?: any | null;
};
export type ListFilterInputTypeOfClientAccountModelFilterInput = {
  all?: ClientAccountModelFilterInput | null;
  any?: boolean | null;
  none?: ClientAccountModelFilterInput | null;
  some?: ClientAccountModelFilterInput | null;
};
export type ClientAccountModelFilterInput = {
  accountDefaultName?: StringOperationFilterInput | null;
  accountId?: ComparableInt32OperationFilterInput | null;
  accountNumber?: StringOperationFilterInput | null;
  accountType?: StringOperationFilterInput | null;
  and?: ReadonlyArray<ClientAccountModelFilterInput> | null;
  custodian?: StringOperationFilterInput | null;
  managementStyle?: StringOperationFilterInput | null;
  or?: ReadonlyArray<ClientAccountModelFilterInput> | null;
  totalBalance?: ComparableDecimalOperationFilterInput | null;
};
export type ComparableInt32OperationFilterInput = {
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: ReadonlyArray<number> | null;
  lt?: number | null;
  lte?: number | null;
  neq?: number | null;
  ngt?: number | null;
  ngte?: number | null;
  nin?: ReadonlyArray<number> | null;
  nlt?: number | null;
  nlte?: number | null;
};
export type StringOperationFilterInput = {
  and?: ReadonlyArray<StringOperationFilterInput> | null;
  contains?: string | null;
  endsWith?: string | null;
  eq?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ncontains?: string | null;
  nendsWith?: string | null;
  neq?: string | null;
  nin?: ReadonlyArray<string | null> | null;
  nstartsWith?: string | null;
  or?: ReadonlyArray<StringOperationFilterInput> | null;
  startsWith?: string | null;
};
export type ListFilterInputTypeOfCashFlowDetailModelFilterInput = {
  all?: CashFlowDetailModelFilterInput | null;
  any?: boolean | null;
  none?: CashFlowDetailModelFilterInput | null;
  some?: CashFlowDetailModelFilterInput | null;
};
export type CashFlowDetailModelFilterInput = {
  accountId?: ComparableInt32OperationFilterInput | null;
  and?: ReadonlyArray<CashFlowDetailModelFilterInput> | null;
  asOfDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  cashFlowDetailId?: IdOperationFilterInput | null;
  incomeEstimateAmount?: ComparableNullableOfDecimalOperationFilterInput | null;
  incomeEstimateDate?: ComparableDateOnlyOperationFilterInput | null;
  incomeTypeName?: StringOperationFilterInput | null;
  isFederalTaxable?: BooleanOperationFilterInput | null;
  isStateTaxable?: BooleanOperationFilterInput | null;
  managementStyle?: StringOperationFilterInput | null;
  or?: ReadonlyArray<CashFlowDetailModelFilterInput> | null;
  taxStatusName?: StringOperationFilterInput | null;
};
export type IdOperationFilterInput = {
  eq?: string | null;
  in?: ReadonlyArray<string | null> | null;
  neq?: string | null;
  nin?: ReadonlyArray<string | null> | null;
};
export type BooleanOperationFilterInput = {
  eq?: boolean | null;
  neq?: boolean | null;
};
export type ComparableDateOnlyOperationFilterInput = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any> | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: ReadonlyArray<any> | null;
  nlt?: any | null;
  nlte?: any | null;
};
export type ComparableNullableOfDecimalOperationFilterInput = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: ReadonlyArray<any | null> | null;
  nlt?: any | null;
  nlte?: any | null;
};
export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: ReadonlyArray<any | null> | null;
  nlt?: any | null;
  nlte?: any | null;
};
export type ListFilterInputTypeOfGetAssetAllocationModelFilterInput = {
  all?: GetAssetAllocationModelFilterInput | null;
  any?: boolean | null;
  none?: GetAssetAllocationModelFilterInput | null;
  some?: GetAssetAllocationModelFilterInput | null;
};
export type GetAssetAllocationModelFilterInput = {
  accountBalance?: ComparableDecimalOperationFilterInput | null;
  accountDefaultName?: StringOperationFilterInput | null;
  accountId?: ComparableInt32OperationFilterInput | null;
  accountNumber?: StringOperationFilterInput | null;
  allocationId?: ComparableInt32OperationFilterInput | null;
  and?: ReadonlyArray<GetAssetAllocationModelFilterInput> | null;
  asOfDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  assetClass?: StringOperationFilterInput | null;
  assetClassBalance?: ComparableDecimalOperationFilterInput | null;
  managementStyle?: StringOperationFilterInput | null;
  or?: ReadonlyArray<GetAssetAllocationModelFilterInput> | null;
  subAssetClass?: StringOperationFilterInput | null;
  subAssetClassBalance?: ComparableDecimalOperationFilterInput | null;
};
export type ListFilterInputTypeOfHoldingsDetailModelFilterInput = {
  all?: HoldingsDetailModelFilterInput | null;
  any?: boolean | null;
  none?: HoldingsDetailModelFilterInput | null;
  some?: HoldingsDetailModelFilterInput | null;
};
export type HoldingsDetailModelFilterInput = {
  accountId?: ComparableInt32OperationFilterInput | null;
  and?: ReadonlyArray<HoldingsDetailModelFilterInput> | null;
  asOfDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  assetClass?: StringOperationFilterInput | null;
  costBasis?: ComparableNullableOfDecimalOperationFilterInput | null;
  currentMarketValue?: ComparableNullableOfDecimalOperationFilterInput | null;
  holdingsId?: ComparableInt32OperationFilterInput | null;
  managementStyle?: StringOperationFilterInput | null;
  or?: ReadonlyArray<HoldingsDetailModelFilterInput> | null;
  price?: ComparableNullableOfDecimalOperationFilterInput | null;
  productName?: StringOperationFilterInput | null;
  quantity?: ComparableNullableOfDecimalOperationFilterInput | null;
  subAssetClass?: StringOperationFilterInput | null;
  ticker?: StringOperationFilterInput | null;
  unrealizedGain?: ComparableNullableOfDecimalOperationFilterInput | null;
  unrealizedGainPercent?: ComparableNullableOfDecimalOperationFilterInput | null;
  yield?: ComparableNullableOfDecimalOperationFilterInput | null;
};
export type ClientAccountActivityModelFilterInput = {
  and?: ReadonlyArray<ClientAccountActivityModelFilterInput> | null;
  data?: ListFilterInputTypeOfAccountActivityDataModelFilterInput | null;
  filterOptions?: AccountActivityFilterOptionsFilterInput | null;
  or?: ReadonlyArray<ClientAccountActivityModelFilterInput> | null;
};
export type ListFilterInputTypeOfAccountActivityDataModelFilterInput = {
  all?: AccountActivityDataModelFilterInput | null;
  any?: boolean | null;
  none?: AccountActivityDataModelFilterInput | null;
  some?: AccountActivityDataModelFilterInput | null;
};
export type AccountActivityDataModelFilterInput = {
  accountDefaultName?: StringOperationFilterInput | null;
  accountId?: ComparableInt32OperationFilterInput | null;
  accountNumber?: StringOperationFilterInput | null;
  amount?: ComparableNullableOfDecimalOperationFilterInput | null;
  and?: ReadonlyArray<AccountActivityDataModelFilterInput> | null;
  dataDate?: ComparableDateTimeOperationFilterInput | null;
  managementStyle?: StringOperationFilterInput | null;
  noiseConfidenceLevel?: ComparableNullableOfDecimalOperationFilterInput | null;
  or?: ReadonlyArray<AccountActivityDataModelFilterInput> | null;
  price?: ComparableNullableOfDecimalOperationFilterInput | null;
  productName?: StringOperationFilterInput | null;
  ticker?: StringOperationFilterInput | null;
  transactionDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  transactionId?: IdOperationFilterInput | null;
  transactionType?: StringOperationFilterInput | null;
  units?: ComparableNullableOfDecimalOperationFilterInput | null;
};
export type ComparableDateTimeOperationFilterInput = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any> | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: ReadonlyArray<any> | null;
  nlt?: any | null;
  nlte?: any | null;
};
export type AccountActivityFilterOptionsFilterInput = {
  and?: ReadonlyArray<AccountActivityFilterOptionsFilterInput> | null;
  managementStyles?: ListStringOperationFilterInput | null;
  maxTransactionAmount?: ComparableNullableOfDecimalOperationFilterInput | null;
  minTransactionAmount?: ComparableNullableOfDecimalOperationFilterInput | null;
  or?: ReadonlyArray<AccountActivityFilterOptionsFilterInput> | null;
  transactionTypes?: ListStringOperationFilterInput | null;
};
export type ListStringOperationFilterInput = {
  all?: StringOperationFilterInput | null;
  any?: boolean | null;
  none?: StringOperationFilterInput | null;
  some?: StringOperationFilterInput | null;
};
export type ListFilterInputTypeOfAccountBalancesModelFilterInput = {
  all?: AccountBalancesModelFilterInput | null;
  any?: boolean | null;
  none?: AccountBalancesModelFilterInput | null;
  some?: AccountBalancesModelFilterInput | null;
};
export type AccountBalancesModelFilterInput = {
  accountDefaultName?: StringOperationFilterInput | null;
  accountId?: ComparableInt32OperationFilterInput | null;
  accountType?: StringOperationFilterInput | null;
  and?: ReadonlyArray<AccountBalancesModelFilterInput> | null;
  asOfDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  beginningMarketValue?: ComparableDecimalOperationFilterInput | null;
  cashBalance?: ComparableDecimalOperationFilterInput | null;
  dailyChange?: ComparableNullableOfDecimalOperationFilterInput | null;
  dailyChangeEndDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  dailyChangeStartDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  endingMarketValue?: ComparableDecimalOperationFilterInput | null;
  isLiability?: BooleanOperationFilterInput | null;
  managementStyle?: StringOperationFilterInput | null;
  marketValue?: ComparableDecimalOperationFilterInput | null;
  netCashFlows?: ComparableDecimalOperationFilterInput | null;
  or?: ReadonlyArray<AccountBalancesModelFilterInput> | null;
  totalBalance?: ComparableDecimalOperationFilterInput | null;
  unrealizedGl?: ComparableDecimalOperationFilterInput | null;
  unrealizedGlPercent?: ComparableNullableOfDecimalOperationFilterInput | null;
  valuationId?: ComparableInt32OperationFilterInput | null;
};
export type ListFilterInputTypeOfPortfolioPerformanceModelFilterInput = {
  all?: PortfolioPerformanceModelFilterInput | null;
  any?: boolean | null;
  none?: PortfolioPerformanceModelFilterInput | null;
  some?: PortfolioPerformanceModelFilterInput | null;
};
export type PortfolioPerformanceModelFilterInput = {
  accountDefaultName?: StringOperationFilterInput | null;
  accountId?: ComparableInt32OperationFilterInput | null;
  and?: ReadonlyArray<PortfolioPerformanceModelFilterInput> | null;
  asOfDate?: ComparableNullableOfDateOnlyOperationFilterInput | null;
  custodian?: StringOperationFilterInput | null;
  fiveYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  inceptionDate?: ComparableNullableOfDateOnlyOperationFilterInput | null;
  managementStyle?: StringOperationFilterInput | null;
  marketValue?: ComparableNullableOfDoubleOperationFilterInput | null;
  monthToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
  netGross?: StringOperationFilterInput | null;
  oneYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  or?: ReadonlyArray<PortfolioPerformanceModelFilterInput> | null;
  portfolioPerformanceId?: ComparableInt32OperationFilterInput | null;
  quarterToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
  sinceInception?: ComparableNullableOfDoubleOperationFilterInput | null;
  tenYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  threeYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  yearToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
};
export type ComparableNullableOfDoubleOperationFilterInput = {
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: ReadonlyArray<number | null> | null;
  lt?: number | null;
  lte?: number | null;
  neq?: number | null;
  ngt?: number | null;
  ngte?: number | null;
  nin?: ReadonlyArray<number | null> | null;
  nlt?: number | null;
  nlte?: number | null;
};
export type ComparableNullableOfDateOnlyOperationFilterInput = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: ReadonlyArray<any | null> | null;
  nlt?: any | null;
  nlte?: any | null;
};
export type ListFilterInputTypeOfPerformanceByManagementStyleModelFilterInput = {
  all?: PerformanceByManagementStyleModelFilterInput | null;
  any?: boolean | null;
  none?: PerformanceByManagementStyleModelFilterInput | null;
  some?: PerformanceByManagementStyleModelFilterInput | null;
};
export type PerformanceByManagementStyleModelFilterInput = {
  and?: ReadonlyArray<PerformanceByManagementStyleModelFilterInput> | null;
  asOfDate?: ComparableNullableOfDateOnlyOperationFilterInput | null;
  fiveYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  householdName?: StringOperationFilterInput | null;
  inceptionDate?: ComparableNullableOfDateOnlyOperationFilterInput | null;
  managementStyle?: StringOperationFilterInput | null;
  marketValue?: ComparableNullableOfDoubleOperationFilterInput | null;
  monthToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
  oneYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  or?: ReadonlyArray<PerformanceByManagementStyleModelFilterInput> | null;
  performanceByManagementStyleId?: ComparableInt32OperationFilterInput | null;
  quarterToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
  sinceInception?: ComparableNullableOfDoubleOperationFilterInput | null;
  tenYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  threeYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  userId?: ComparableInt32OperationFilterInput | null;
  yearToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
};
export type ListFilterInputTypeOfPerformanceByAssetClassForAssetValueModelFilterInput = {
  all?: PerformanceByAssetClassForAssetValueModelFilterInput | null;
  any?: boolean | null;
  none?: PerformanceByAssetClassForAssetValueModelFilterInput | null;
  some?: PerformanceByAssetClassForAssetValueModelFilterInput | null;
};
export type PerformanceByAssetClassForAssetValueModelFilterInput = {
  and?: ReadonlyArray<PerformanceByAssetClassForAssetValueModelFilterInput> | null;
  asOfDate?: ComparableNullableOfDateOnlyOperationFilterInput | null;
  assetClassName?: StringOperationFilterInput | null;
  fiveYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  householdName?: StringOperationFilterInput | null;
  inceptionDate?: ComparableNullableOfDateOnlyOperationFilterInput | null;
  marketValue?: ComparableNullableOfDoubleOperationFilterInput | null;
  monthToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
  oneYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  or?: ReadonlyArray<PerformanceByAssetClassForAssetValueModelFilterInput> | null;
  performanceByAssetClassForAssetValueId?: ComparableInt32OperationFilterInput | null;
  quarterToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
  sinceInception?: ComparableNullableOfDoubleOperationFilterInput | null;
  tenYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  threeYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  yearToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
};
export type ListFilterInputTypeOfPerformanceBySubAssetClassForSubAssetValueModelFilterInput = {
  all?: PerformanceBySubAssetClassForSubAssetValueModelFilterInput | null;
  any?: boolean | null;
  none?: PerformanceBySubAssetClassForSubAssetValueModelFilterInput | null;
  some?: PerformanceBySubAssetClassForSubAssetValueModelFilterInput | null;
};
export type PerformanceBySubAssetClassForSubAssetValueModelFilterInput = {
  and?: ReadonlyArray<PerformanceBySubAssetClassForSubAssetValueModelFilterInput> | null;
  asOfDate?: ComparableNullableOfDateOnlyOperationFilterInput | null;
  fiveYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  householdName?: StringOperationFilterInput | null;
  inceptionDate?: ComparableNullableOfDateOnlyOperationFilterInput | null;
  marketValue?: ComparableNullableOfDoubleOperationFilterInput | null;
  monthToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
  oneYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  or?: ReadonlyArray<PerformanceBySubAssetClassForSubAssetValueModelFilterInput> | null;
  performanceBySubAssetClassForSubAssetValueId?: ComparableInt32OperationFilterInput | null;
  quarterToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
  sinceInception?: ComparableNullableOfDoubleOperationFilterInput | null;
  subAssetClassName?: StringOperationFilterInput | null;
  tenYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  threeYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  yearToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
};
export type ListFilterInputTypeOfPerformanceByHoldingsForAccountValueModelFilterInput = {
  all?: PerformanceByHoldingsForAccountValueModelFilterInput | null;
  any?: boolean | null;
  none?: PerformanceByHoldingsForAccountValueModelFilterInput | null;
  some?: PerformanceByHoldingsForAccountValueModelFilterInput | null;
};
export type PerformanceByHoldingsForAccountValueModelFilterInput = {
  accountId?: ComparableInt32OperationFilterInput | null;
  accountName?: StringOperationFilterInput | null;
  and?: ReadonlyArray<PerformanceByHoldingsForAccountValueModelFilterInput> | null;
  asOfDate?: ComparableNullableOfDateOnlyOperationFilterInput | null;
  fiveYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  householdName?: StringOperationFilterInput | null;
  inceptionDate?: ComparableNullableOfDateOnlyOperationFilterInput | null;
  marketValue?: ComparableNullableOfDoubleOperationFilterInput | null;
  monthToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
  oneYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  or?: ReadonlyArray<PerformanceByHoldingsForAccountValueModelFilterInput> | null;
  performanceByHoldingsForAccountValueId?: ComparableInt32OperationFilterInput | null;
  productName?: StringOperationFilterInput | null;
  quarterToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
  sinceInception?: ComparableNullableOfDoubleOperationFilterInput | null;
  tenYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  threeYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  yearToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
};
export type ListFilterInputTypeOfPerformanceByHouseholdModelFilterInput = {
  all?: PerformanceByHouseholdModelFilterInput | null;
  any?: boolean | null;
  none?: PerformanceByHouseholdModelFilterInput | null;
  some?: PerformanceByHouseholdModelFilterInput | null;
};
export type PerformanceByHouseholdModelFilterInput = {
  and?: ReadonlyArray<PerformanceByHouseholdModelFilterInput> | null;
  asOfDate?: ComparableNullableOfDateOnlyOperationFilterInput | null;
  fiveYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  householdName?: StringOperationFilterInput | null;
  inceptionDate?: ComparableNullableOfDateOnlyOperationFilterInput | null;
  marketValue?: ComparableNullableOfDoubleOperationFilterInput | null;
  monthToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
  oneYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  or?: ReadonlyArray<PerformanceByHouseholdModelFilterInput> | null;
  performanceByHouseholdId?: ComparableInt32OperationFilterInput | null;
  quarterToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
  sinceInception?: ComparableNullableOfDoubleOperationFilterInput | null;
  tenYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  threeYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  userId?: ComparableInt32OperationFilterInput | null;
  yearToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
};
export type ListFilterInputTypeOfPerformanceByProductModelFilterInput = {
  all?: PerformanceByProductModelFilterInput | null;
  any?: boolean | null;
  none?: PerformanceByProductModelFilterInput | null;
  some?: PerformanceByProductModelFilterInput | null;
};
export type PerformanceByProductModelFilterInput = {
  and?: ReadonlyArray<PerformanceByProductModelFilterInput> | null;
  asOfDate?: ComparableNullableOfDateOnlyOperationFilterInput | null;
  assetClassName?: StringOperationFilterInput | null;
  fiveYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  householdName?: StringOperationFilterInput | null;
  inceptionDate?: ComparableNullableOfDateOnlyOperationFilterInput | null;
  marketValue?: ComparableNullableOfDoubleOperationFilterInput | null;
  monthToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
  oneYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  or?: ReadonlyArray<PerformanceByProductModelFilterInput> | null;
  performanceByProductId?: ComparableInt32OperationFilterInput | null;
  productName?: StringOperationFilterInput | null;
  quarterToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
  sinceInception?: ComparableNullableOfDoubleOperationFilterInput | null;
  subAssetClassName?: StringOperationFilterInput | null;
  tenYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  threeYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  yearToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
};
export type ListFilterInputTypeOfPerformanceByAccountGroupModelFilterInput = {
  all?: PerformanceByAccountGroupModelFilterInput | null;
  any?: boolean | null;
  none?: PerformanceByAccountGroupModelFilterInput | null;
  some?: PerformanceByAccountGroupModelFilterInput | null;
};
export type PerformanceByAccountGroupModelFilterInput = {
  accountGroupId?: StringOperationFilterInput | null;
  and?: ReadonlyArray<PerformanceByAccountGroupModelFilterInput> | null;
  asOfDate?: ComparableNullableOfDateOnlyOperationFilterInput | null;
  fiveYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  inceptionDate?: ComparableNullableOfDateOnlyOperationFilterInput | null;
  marketValue?: ComparableNullableOfDoubleOperationFilterInput | null;
  monthToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
  oneYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  or?: ReadonlyArray<PerformanceByAccountGroupModelFilterInput> | null;
  performanceByAccountGroupId?: ComparableInt32OperationFilterInput | null;
  quarterToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
  sinceInception?: ComparableNullableOfDoubleOperationFilterInput | null;
  tenYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  threeYearReturns?: ComparableNullableOfDoubleOperationFilterInput | null;
  yearToDate?: ComparableNullableOfDoubleOperationFilterInput | null;
};
export type GetAssetAllocationInput = {
  financialAccountIds: ReadonlyArray<number>;
};
export type AssetAllocationPreviewQueryReaderQuery$variables = {
  assetAllocationInput: GetAssetAllocationInput;
  clientWhere?: ClientModelFilterInput | null;
  input: GetClientUserInput;
};
export type AssetAllocationPreviewQueryReaderQuery$data = {
  readonly clientsummary_viewer: {
    readonly client: ReadonlyArray<{
      readonly accounts: ReadonlyArray<{
        readonly accountDefaultName: string;
        readonly accountId: number;
        readonly accountNumber: string | null;
        readonly custodian: string | null;
        readonly managementStyle: string | null;
        readonly totalBalance: any;
      } | null> | null;
      readonly getAssetAllocations: ReadonlyArray<{
        readonly accountBalance: any;
        readonly accountDefaultName: string;
        readonly accountId: number;
        readonly accountNumber: string;
        readonly allocationId: number;
        readonly asOfDate: any | null;
        readonly assetClass: string;
        readonly assetClassBalance: any;
        readonly managementStyle: string;
        readonly subAssetClass: string;
        readonly subAssetClassBalance: any;
      }>;
      readonly userId: any;
    }>;
  };
  readonly userprofile_viewer: {
    readonly clientUser: ReadonlyArray<{
      readonly accountGroups: ReadonlyArray<{
        readonly accounts: ReadonlyArray<{
          readonly financialAccountId: number;
          readonly id: any;
        }>;
        readonly id: string;
        readonly name: string;
      }>;
      readonly accounts: ReadonlyArray<{
        readonly accountNickname: string;
        readonly financialAccountId: number;
      }>;
      readonly id: any;
    }>;
  } | null;
};
export type AssetAllocationPreviewQueryReaderQuery = {
  response: AssetAllocationPreviewQueryReaderQuery$data;
  variables: AssetAllocationPreviewQueryReaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assetAllocationInput"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientWhere"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "financialAccountId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "input",
      "variableName": "input"
    }
  ],
  "concreteType": "ClientUserViewModel",
  "kind": "LinkedField",
  "name": "clientUser",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserAccount",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountNickname",
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountGroup",
      "kind": "LinkedField",
      "name": "accountGroups",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "accounts",
          "plural": true,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountDefaultName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "managementStyle",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "clientWhere"
    }
  ],
  "concreteType": "ClientModel",
  "kind": "LinkedField",
  "name": "client",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientAccountModel",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": true,
      "selections": [
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "custodian",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalBalance",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "assetAllocationInput"
        }
      ],
      "concreteType": "GetAssetAllocationModel",
      "kind": "LinkedField",
      "name": "getAssetAllocations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountBalance",
          "storageKey": null
        },
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allocationId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "asOfDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assetClass",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assetClassBalance",
          "storageKey": null
        },
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subAssetClass",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subAssetClassBalance",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssetAllocationPreviewQueryReaderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfileViewer",
        "kind": "LinkedField",
        "name": "userprofile_viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientSummaryViewer",
        "kind": "LinkedField",
        "name": "clientsummary_viewer",
        "plural": false,
        "selections": [
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AssetAllocationPreviewQueryReaderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfileViewer",
        "kind": "LinkedField",
        "name": "userprofile_viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientSummaryViewer",
        "kind": "LinkedField",
        "name": "clientsummary_viewer",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f20383f417b200181dd92d513f93a041",
    "id": null,
    "metadata": {},
    "name": "AssetAllocationPreviewQueryReaderQuery",
    "operationKind": "query",
    "text": "query AssetAllocationPreviewQueryReaderQuery(\n  $input: GetClientUserInput!\n  $clientWhere: ClientModelFilterInput\n  $assetAllocationInput: GetAssetAllocationInput!\n) {\n  userprofile_viewer {\n    clientUser(input: $input) {\n      id\n      accounts {\n        accountNickname\n        financialAccountId\n      }\n      accountGroups {\n        id\n        name\n        accounts {\n          id\n          financialAccountId\n        }\n      }\n    }\n    id\n  }\n  clientsummary_viewer {\n    client(where: $clientWhere) {\n      accounts {\n        accountDefaultName\n        accountId\n        accountNumber\n        managementStyle\n        custodian\n        totalBalance\n      }\n      userId\n      getAssetAllocations(input: $assetAllocationInput) {\n        accountBalance\n        accountDefaultName\n        accountId\n        accountNumber\n        allocationId\n        asOfDate\n        assetClass\n        assetClassBalance\n        managementStyle\n        subAssetClass\n        subAssetClassBalance\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "39cc1371a9c720707f2f7ee28b0a4a67";

export default node;
